<template>
  <v-dialog :value="load"
            :max-width="($vuetify.breakpoint.xs ? '95%' :
              (($vuetify.breakpoint.sm ? '80' :
              $vuetify.breakpoint.md) ? '70%' :
              ($vuetify.breakpoint.lg ? '55%' :
              '45%')))"
            transition="scale-transition" scrollable persistent>
    <v-card dark>
      <v-card-title class="display-1">
        <v-spacer></v-spacer>

        {{ $t('common.dialog__customer-notification-title') }}

        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text class="title" style="line-height: 1.5 !important;">
        {{
          $t('common.dialog__customer-notification', {
            email:
                (this.user_email !== '' ? this.user_email : 'customer')
          })
        }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="openPrivacyPolicyPage" color="orange" dark flat>
          {{ $t('common.dialog__customer-notification-button-learn-more') }}
        </v-btn>

        <v-btn @click="$store.dispatch('acceptCustomerCookies');" color="white"
               light>
          {{ $t('common.dialog__customer-notification-button-accept') }}
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomerNotificationDialog',
  props: {
    load: {type: Boolean, default: false},
    user_email: {type: String, default: ""},
  },
  methods: {
    openPrivacyPolicyPage() {
      if (this.$router.currentRoute.fullPath !== '/datenschutz') {
        this.$router.push('/datenschutz');
      }
      this.$store.dispatch('toggleCookiesDialog',
          {type: 'customer', value: false});
    },
  },
  created() {
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
